<template>
<div class="mainform">
  <div class="form">
    <el-form :model="form" label-width="100px">
      <el-form-item label="角色名称" >
          <el-input v-model="form.roleCode" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="角色描述" >
          <el-input  type="textarea" :rows="5"  v-model="form.roleDesc"></el-input>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
import { addData } from './js/roledata.js'
export default {
  name: 'RoleDialogBody',
  props: ['showData', 'roleType'],
  data: function () {
    var formdata = {}
    if (this.showData) {
      formdata = this.showData
    } else {
      formdata = addData
    }
    formdata.isSupplierRole = this.roleType
    return {
      form: formdata
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    :deep(.form){
      background: none;
      .content{
      padding: 0px 0;
    }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
</style>
